import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "audience"
    }}>{`Audience`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`PTOs wondering if they can use NOD.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Software developers who want to get an impression of the scope of a NOD integration.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Business developers looking at new opportunities the NOD platform can provide.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      